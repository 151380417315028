<template>
    <div>
      <b-row class="card">
        <div class="w-100">
          <h5 class="title-card">
            <i class="fas fa-solar-panel"></i> Estruturas Criadas
          </h5>
        </div>
        <div class="w-100 p-3">
          <b-table
          id="tb-structures"
          responsive
          bordered
          :current-page="currentPage"
          :per-page="perPage"
          :busy="load"
          :items="structures"
          :fields="columsTableStructures">
            <template #table-busy>
              <div class="text-center text-success my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Carregando...</strong>
              </div>
            </template>
            <template v-slot:cell(actions)="data">
                <div>
                <b-button
                :to="{ name: 'structure-edit', params: { id: data.item.id } }"
                title="Editar estrutura"
                size="sm"
                class="mr-1"
                variant="warning">
                    <i class="fas fa-pencil-alt"></i> Editar
                </b-button>
                </div>
            </template>
          </b-table>
        </div>

        <div class="w-100 py-3" v-if="totalRows > 15">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="center"
            size="sm"
            class="my-0"
          ></b-pagination>
        </div>
      </b-row>
    </div>
  </template>

<script>
import MessagesMixin from '@/mixins/messages-mixin';
import StructureService from '@/Services/StructureService';

export default {
  data() {
    return {
      id_remove: null,
      columsTableStructures: StructureService.getFieldsTableStructures(),
      load: true,
      totalRows: 0,
      currentPage: 1,
      perPage: 15,
      structures: [],
      filters: {
        type: '',
      },
      payload: {
        type: '',
        price: '',
      },
    };
  },

  mixins: [MessagesMixin],

  methods: {
    async getAll() {
      // const filters = { ...this.filters };

      try {
        const { data } = await StructureService.getList();
        console.log(data);
        this.totalRows = data.length;
        this.structures = data.map((item) => ({
          id: item.id,
          type: item.label,
          price: this.$options.filters.currency(item.price),
        }));
      } catch (error) {
        console.log(error);
      }
      this.load = false;
    },
    confirmeDelete(id) {
      this.id_remove = id;
    },
    resetInput() {
      this.filters.type = '';
    },
  },

  created() {
    this.getAll();
  },
};
</script>
